//
// hello world!
// Files must be loaded in the following order:
// 1) base/application level styles
// 2) page level styles
// 3) component level styles
// 4) responsive styles
//

$assetsPath:  '//cdn.switchfly.com/static/4c72c35/resources/shopping/images/application';

@import       "../../vendor/sass-bootstrap-3.0.16/sass/sass-bootstrap-shopping",
              // sass-bootstrap-shopping also imports app-theme
              "app-icons/_icon-font",
              "components/_site-toolbar";
              // We don't need bootstrap-helpers (shared) in this app.

// APP UI COMPONENT STYLES (These MUST be defined before BASE STYLES (which are overrides for our base/library styles))
@import       "ui-components/_buttons",
              "ui-components/_button-groups",
              "ui-components/_modals",
              "ui-components/_tags",
              "ui-components/_badges",
              "ui-components/_safekey-modal";

// -----------------------------------------------------------------
// SHOPPING APP OVERRIDES
// -----------------------------------------------------------------

// BASE STYLES
@import       "app-global.base",
              "app-global-responsive-handheld.base"; // Extends base case (desktop, tablet) for handheld usage (for when users aren't recognized as iOS/Android)

// PAGES
@import       "pages/_404",
              "pages/_search-results",
              "pages/_checkout",
              "pages/_manage-booking",
              "pages/_ui-patterns";

// SKELETON SCREENS
@import       "skeleton-screens/_skeleton-hotel-detail",
              "skeleton-screens/_skeleton-activity-detail";

// COMPONENTS
@import       "ui-components/_flexbox",
              "ui-components/_cssgrid",
              "ui-components/_grid-info",
              "ui-components/_stack",
              "components/_header-compact",
              "components/_search_form",
              "components/_kards",
              "components/_media",
              "components/_products.base",
              "components/_product-filters",
              "components/_branded-flights",
              "components/_compare-fare-types-modal",
              "components/_date-carousel",
              "components/_air-flight-matrix.base",
              "components/_air-flight-matrix-leg-based",
              "components/_dashcart.base",
              "components/_dashbar.base",
              "components/_seatmap.base",
              "components/_seatmap-preview.base",
              "components/_seat-map-legend.base",
              "components/_ancillary-modules.base",
              "components/_ancillary-hub.base",
              "components/_itinerary.base",
              "components/_promotions",
              "components/_typeahead",
              "components/_bing-map",
              "components/_centinel-widget",
              "components/_product-images-mosaic",
              "components/_hotel-images",
              "components/_floating-cart-element",
              "components/_confirmation",
              "components/_dashcart-summary",
              "components/_social-media-links",
              "components/_trip_summary.base",
              "components/_wells",
              "components/_pagination",
              "components/_pointsAllocation",
              "components/_filter-slider",
              "../../vendor/bootstrap-datepicker/css/datepicker3",
              "components/_datepicker",
              "../../vendor/intl-tel-input/src/css/intlTelInput",
              "components/_international-phone-numbers",
              "components/_bag-information",
              "components/_card",
              "components/_number-stepper",
              "components/_bags.base",
              "components/_shopping-cart-details.base",
              "components/_web-chat.base",
              "components/_cart-summary",
              "components/_air-layovers",
              "components/_branded-fare-upsell-modal",
              "components/_progress-bar.base",
              "components/_travel-credit-alert.base",
              "components/_panel-drawer",
              "components/_air-pnr-result-row-branded-fare",
              "components/_hotel-similar-product";

// RESPONSIVE STYLES
@import       "app-responsive",
              "app-print";

// ANIMATIONS
@import       "animations/animate";

// RTL (Right-To-Left) Styles
@import       "app-rtl";