$assetsPath: '//cdn.switchfly.com/static/4c72c35/resources/shopping/images/themes/iberia';

// Imports and mixins
// -------------------------

@import "_webfonts";

// Client Colors
// -------------------------

$iberia-red: #d7192d;
$iberia-yellow: #fcd100;
$iberia-gray: #454240;
$iberia-dark-red: #6f000c;

// Brand colors
// -------------------------

$brand-primary: $iberia-red;
$brand-featured: $iberia-red;
$brand-danger: $iberia-red;

// Site Header
// -------------------------

// Handheld (<480px)
$site-header-brand-container-col-width-handheld: 10;
$site-header-loyalty-container-col-width-handheld: 12;

// This is tablet in portrait orientation
$site-header-brand-container-col-width-tablet: 10;
$site-header-loyalty-container-col-width-tablet: 12;

// $screen-sm to $screen-md
$site-header-brand-container-col-width: 10;
$site-header-loyalty-container-col-width: 10;

// $screen-md to $screen-lg
$site-header-brand-container-col-width-md: 10;
$site-header-loyalty-container-col-width-md: 10;

// $screen-lg +
$site-header-brand-container-col-width-lg: 10;
$site-header-loyalty-container-col-width-lg: 10;

// Scaffolding
// -------------------------

$body-bg: #fff;
$text-color: #333;

// Links
// -------------------------

$link-color: $iberia-red;

// Typography
// -------------------------

$font-family-sans-serif: Verdana, Arial, Helvetica, sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-base: 12px;
$font-size-h1: 42px;
$font-size-h2: 32px;
$font-size-h3: 25px;

$headings-font-family: iberia_headline;
$headings-font-weight: 600;

// Buttons
// -------------------------

$btn-border-width: 1px;
$btn-border-radius: 0;

$btn-primary-color: #fff;
$btn-primary-bg: $iberia-red;
$btn-primary-border: $gray-lighter;

$btn-secondary-color: $text-color;
$btn-secondary-bg: eee;
$btn-secondary-border: $text-color;
$btn-secondary-color-hover: $text-color;
$btn-secondary-bg-color-hover: #ddd;
$btn-secondary-border-hover: $text-color;

$btn-default-color: $text-color;
$btn-default-bg: #eee;
$btn-default-border: #e1e1e1;

$btn-default-color-hover: $text-color;
$btn-default-bg-color-hover: #dadada;
$btn-default-border-hover: #c3c3c3;

// Pagination
// -------------------------

$pagination-hover-bg: $iberia-red;
$pagination-active-bg: $iberia-dark-red;

// Miscellaneous
// -------------------------

$hr-border: #ccc;

// Helper classes
// -------------------------

$points-text-color: $text-color;

// Global
// -------------------------

$site-header-background: $iberia-red;

$site-header-text-color: #353535;

$site-header-border-bottom-width: 0;

$site-header-logo-primary-file-path: "#{$assetsPath}/iberia_logo.png";

// Page Level Styles
// -------------------------

$section-header-text-color: #4d4d4d;
$section-header-border-bottom-color: $hr-border;
$section-header-background-color_handheld: #fff;

// Page Main Contents Container
// -------------------------

// Page Aside Contents Container
// -------------------------

$aside-contents-section-background-color: #fff;

// Datepicker Component
// -------------------------

$datepicker-day-range-background-color: $iberia-red;
$datepicker-day-selected-background-color: $iberia-dark-red;

// Points Slider Component
// -------------------------

$points-slider-selection-background-color: #858585;
$points-slider-handle-background-color: $iberia-red;
$points-slider-handle-text-color: $iberia-yellow;

// Filter Components
// -------------------------

$filter-slider-selection-background-color: $iberia-red;

$toggle-view-label-background-color: $iberia-red;
$toggle-view-label-background-color-hover: $iberia-dark-red;

// Site Header
// -------------------------

$navbar-default-link-color: $site-header-text-color;
$navbar-default-link-active-color: $iberia-gray;
$navbar-default-link-hover-color: $iberia-gray;

// Site Footer
// -------------------------

$site-footer-text-color: $iberia-gray;

// Search Form
$search-form-container-background-color: #fff;
$search-form-container-background-opacity: 0.9;

// APPLICATION ICONS
// -------------------------

$icon-product__bundle: "\f0f2";
$icon-product__hotel: "\f0f8";
$icon-product__car: "\f018";
$icon-product__activity: "\f145";

// Custom CSS
// -------------------------

// TODO review these settings for site-header and page-content, can they be removed?

#site-header {

  > .container {
    max-width: 100%;
    padding: 0px;
  }

  .header-top {
    background: #eee;
    color: $text-color;

    > .container {
      display: flex;
      align-items: center;
    }
  }

  .navbar-default {
    display: flex;
    align-items: flex-start;

    .navbar-nav > li > a {
      padding-left: 0;
    }

    .clearfix {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      float: none;
    }
  }

  .navbar-right {
    display: flex;
    align-items: flex-start;
    white-space: nowrap;
  }

  .header-brand-container {
    display: flex;
  }

  .logo-primary {
    height: 50px;
  }

  .oneWorld {
    width: 37px;
    background: url(/resources/shopping/images/themes/iberia/homepage_sprite.png) no-repeat 0 -45px;
    height: 37px;
    margin-left: auto;
  }

  .logo-container {
    display: flex;
    align-items: center;
  }

  .header-loyalty-container {
    padding-left: 8px;
    padding-right: 0;
    flex-grow: 1;
  }

  .loyalty-summary {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    .loyalty-profile > label {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding-right: 5px;
    }

    .profile-name,
    .points {
      padding: 0 5px;
      border-right: 1px solid $text-color;
    }

    .link {
      color: $iberia-dark-red;
    }
  }

  .points {
    color: inherit;
  }
}

#search-form-container {
  section:nth-child(2) .btn-link {
    width: 100%;
    text-align: center;
  }
}

// INFO: fix color contrast issues in pagination
.pagination {
  > li > a,
  > li > span {
    &:hover,
    &:focus {
      color: $btn-primary-color;
    }
  }
}

#site-footer {
  [class*="-logo"] {
    background-image: url("#{$assetsPath}/homepage_sprite.png");
    display: inline-block;
  }

  .avios-logo {
    width: 50px;
    height: 50px;
    background-position: -49px -43px;
  }

  .tripadvisor-logo {
    width: 115px;
    height: 28px;
    background-position: 7px -98px;
  }

  .iberia-footer-logo {
    width: 167px;
    height: 38px;
    margin-top: 15px;
    background-position: 7px -122px;
  }
}

.page-content {
  .btn {
    padding: 13px 20px;
  }

  .btn-primary {
    border: $iberia-red;

    &:hover {
      @include gradient-vertical($iberia-red, $iberia-dark-red);
    }
    &:active {
      background-color: $iberia-dark-red;
    }
  }
}

@media (min-width: $screen-lg) {
  #search-base .hero {
    min-height: 740px;
  }
}

@media (min-width: $screen-md + 1) and (max-width: $screen-lg) {
  #search-base .hero {
    min-height: 640px;
  }
}

@media (min-width: $screen-sm + 1) and (max-width: $screen-md) {
  #search-base .hero {
    min-height: 640px;
    background-position: 40%;
  }
}

@media (max-width: $screen-sm) {

  #site-header .navbar-right {
    flex-direction: column;
  }

  #search-base .hero {
    background-position: 40%;
  }

  .case-base #search-form-container .btn-hero-group {
    .btn-primary {
      flex-direction: column;
      margin-top: 2px;
    }
  }
}

@media (min-width: $screen-sm) {
  #site-header .navbar-right > ul:last-child > li > a:before {
    content: "|";
    padding-right: 10px;
  }
}

// Search Form

.case-base #search-form-container,
.case-handheld #shopping-page-container {

  .btn-hero-group {

    .btn {
      height: 100%;
    }
    .btn-primary {

      i {
        font-family: "Iberia Icons";
        line-height: 30px;
        height: 30px;
      }

      .icon-product__bundle {
        font-size: 60px;
      }

      .icon-product__activity {
        font-size: 40px;
      }

      .icon-product__hotel {
        font-size: 28px;
      }

      .icon-product__car {
        font-size: 28px;
      }

      &.active,
      &.active:hover {
        color: $iberia-red;
        background-image: none;
        @include alpha-background-color(rgba($search-form-container-background-color, $search-form-container-background-opacity), $text-color);
      }
    }
  }
}

.case-base #search-form-container {

  .btn-hero-group {
    .btn-primary {
      display: flex;
      align-items: center;
    }
  }
}

.case-handheld #site-header .loyalty-summary {
  .profile-name,
  .points {
    border-right: none;
  }
}

.case-base .footer-center {
  justify-content: flex-end;
}

.case-handheld .footer-center {
  justify-content: center;
}

@media (max-width: $screen-sm) {
  #site-footer .footer-center {
    display: flex;
    align-items: center;
    height: 35px;
  }
}
