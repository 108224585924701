// Variables are defined in app-theme.scss

@font-face {
  font-family: '#{$app-icon-font-family}';
  src:  url('#{$app-icon-font-path}/#{$app-icon-font-family}.eot?l0k5wp');
  src:  url('#{$app-icon-font-path}/#{$app-icon-font-family}.eot?l0k5wp#iefix') format('embedded-opentype'),
  url('#{$app-icon-font-path}/#{$app-icon-font-family}.ttf?l0k5wp') format('truetype'),
  url('#{$app-icon-font-path}/#{$app-icon-font-family}.woff?l0k5wp') format('woff'),
  url('#{$app-icon-font-path}/#{$app-icon-font-family}.svg?l0k5wp##{$app-icon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.icon {
  /* Possible that we need to use !important to prevent issues with browser extensions that change fonts - removed however b/c client theme overrides would also need !important */
  font-family: '#{$app-icon-font-family}';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: $icon-vertical-align;
  display: inline-block;
  font-size: $icon-font-size; // Sets icon to 120% of sibling font-size
  line-height: normal;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    display: inline-block;
  }
}

// Sets common width for toggled icons
.icon-container {
  display: inline-block !important;
  width: 1.5em;
  padding-right: 0 !important;
  text-align: center;
  text-decoration: none;
}

// Aligns icon to top next to multiline text
.icon-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 2px;

  .icon {
    margin-top: -2px;
  }
}

// HELPERS

/* makes the font 33% larger relative to the icon container */
.icon-large {
  &:before {
    vertical-align: -5%; /* Decreased from -10% to -5% in order to compensate the bad alignment of some icons in the font (chevron icons for example)*/
    font-size: 1.3333333333333333em;
  }
}

.icon-2x {
  font-size: 2em;
}

.icon-3x {
  font-size: 3em;
}

.icon-4x {
  font-size: 4em;
}

.icon-5x {
  font-size: 5em;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}

@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.icon-spin {
  display: inline-block;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

.icon-rotate-45:before {
  @include transform(rotate(45deg));
}

.icon-rotate-neg45:before {
  @include transform(rotate(-45deg));
}

.icon-rotate-90:before {
  @include transform(rotate(90deg));
}

.icon-rotate-neg90:before {
  @include transform(rotate(-90deg));
}

// ICON MAPPINGS

.icon-clear {
  &:before {
    content: $icon-clear;
  }
}
.icon-meeting {
  &:before {
    content: $icon-meeting;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-car__num-pax {
  &:before {
    content: $icon-car__num-pax;
  }
}
.icon-car__unlimited-miles {
  &:before {
    content: $icon-car__unlimited-miles;
  }
}
.icon-car__drive-type {
  &:before {
    content: $icon-car__drive-type;
  }
}
.icon-activity--remove {
  &:before {
    content: $icon-activity--remove;
  }
}
.icon-activity--add {
  &:before {
    content: $icon-activity--add;
  }
}
.icon-closet {
  &:before {
    content: $icon-closet;
  }
}
.icon-galley {
  &:before {
    content: $icon-galley;
  }
}
.icon-search-form__date {
  &:before {
    content: $icon-search-form__date;
  }
}
.icon-gym-fitness {
  &:before {
    content: $icon-gym-fitness;
  }
}
.icon-all-inclusive {
  &:before {
    content: $icon-all-inclusive;
  }
}
.icon-restrooms {
  &:before {
    content: $icon-restrooms;
  }
}
.icon-car__transmission {
  &:before {
    content: $icon-car__transmission;
  }
}
.icon-car__door-count {
  &:before {
    content: $icon-car__door-count;
  }
}
.icon-spa {
  &:before {
    content: $icon-spa;
  }
}
.icon-tennis {
  &:before {
    content: $icon-tennis;
  }
}
.icon-ski-in-out {
  &:before {
    content: $icon-ski-in-out;
  }
}
.icon-restaurant {
  &:before {
    content: $icon-restaurant;
  }
}
.icon-child-services {
  &:before {
    content: $icon-child-services;
  }
}
.icon-teen-programs {
  &:before {
    content: $icon-teen-programs;
  }
}
.icon-casino {
  &:before {
    content: $icon-casino;
  }
}
.icon-airport-shuttle {
  &:before {
    content: $icon-airport-shuttle;
  }
}
.icon-secure-connection {
  &:before {
    content: $icon-secure-connection;
  }
}
.icon-undo {
  &:before {
    content: $icon-undo;
  }
}
.icon-product__bundle {
  &:before {
    content: $icon-product__bundle; // Note: this is double width
  }
}
.icon-product__flight {
  &:before {
    content: $icon-product__flight;
  }
}
.icon-product__hotel {
  &:before {
    content: $icon-product__hotel;
  }
}
.icon-product__car {
  &:before {
    content: $icon-product__car;
  }
}
.icon-product__activity {
  &:before {
    content: $icon-product__activity;
  }
}
.icon-product__cruise {
  &:before {
    content: $icon-product__cruise;
  }
}
.icon-product__train {
  &:before {
    content: $icon-product__train;
  }
}
.icon-product__deal {
  &:before {
    content: $icon-product__deal;
  }
}
.icon-product__departure_point {
  &:before {
    content: $icon-product__departure_point;
  }
}
.icon-product__return_point {
  &:before {
    content: $icon-product__return_point;
  }
}
.icon-product__language {
  &:before {
    content: $icon-product__language;
  }
}
.icon-product__excluded {
  &:before {
    content: $icon-product__excluded;
  }
}
.icon-product__included {
  &:before {
    content: $icon-product__included;
  }
}
.icon-product__duration {
  &:before {
    content: $icon-product__duration;
  }
}
.icon-rating__half {
  &:before {
    content: $icon-rating__half;
  }
}
.icon-refresh-replay {
  &:before {
    content: $icon-refresh-replay;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-car__fuel-type {
  &:before {
    content: $icon-car__fuel-type;
  }
}
.icon-air-conditioning {
  &:before {
    content: $icon-air-conditioning;
  }
}
.icon-wifi {
  &:before {
    content: $icon-wifi;
  }
}
.icon-smoke-free {
  &:before {
    content: $icon-smoke-free;
  }
}
.icon-room-service {
  &:before {
    content: $icon-room-service;
  }
}
.icon-pets {
  &:before {
    content: $icon-pets;
  }
}
.icon-pets--small {
  &:before {
    content: $icon-pets--small;
  }
}
.icon-internet-device-hub {
  &:before {
    content: $icon-internet-device-hub;
  }
}
.icon-wheelchair-accessible {
  &:before {
    content: $icon-wheelchair-accessible;
  }
}
.icon-golf {
  &:before {
    content: $icon-golf;
  }
}
.icon-free-parking {
  &:before {
    content: $icon-free-parking;
  }
}
.icon-continental-breakfast {
  &:before {
    content: $icon-continental-breakfast;
  }
}
.icon-babysitting-child-care {
  &:before {
    content: $icon-babysitting-child-care;
  }
}
.icon-business-center {
  &:before {
    content: $icon-business-center;
  }
}
.icon-beach {
  &:before {
    content: $icon-beach;
  }
}
.icon-pool {
  &:before {
    content: $icon-pool;
  }
}
.icon-pool--swimming {
  &:before {
    content: $icon-pool--swimming;
  }
}
.icon-bar--lounge {
  &:before {
    content: $icon-bar--lounge;
  }
}
.icon-bar--local {
  &:before {
    content: $icon-bar--local;
  }
}
.icon-seat-map__exit-left {
  &:before {
    content: $icon-seat-map__exit-left;
  }
}
.icon-seat-map__exit-right {
  &:before {
    content: $icon-seat-map__exit-right;
  }
}
.icon-seat-map__seat--selected {
  &:before {
    content: $icon-seat-map__seat--selected; // shared with icon-input__checkbox-toggle--selected
  }
}
.icon-seat-map__stairs {
  &:before {
    content: $icon-seat-map__stairs;
  }
}
.icon-scroll--prev-item {
  &:before {
    content: $icon-scroll--prev-item;
  }
}
.icon-scroll--next-item {
  &:before {
    content: $icon-scroll--next-item;
  }
}
.icon-back-to-results {
  &:before {
    content: $icon-back-to-results;
  }
}
.icon-booking-continue {
  &:before {
    content: $icon-booking-continue;
  }
}
.icon-list-item__special {
  &:before {
    content: $icon-list-item__special;
  }
}
.icon-list-item__default-amenity {
  &:before {
    content: $icon-list-item__default-amenity;
  }
}
.icon-list-item__prepaid {
  &:before {
    content: $icon-list-item__prepaid;
  }
}
.icon-flight__take-off {
  &:before {
    content: $icon-flight__take-off;
  }
}
.icon-flight__landing {
  &:before {
    content: $icon-flight__landing;
  }
}
.icon-alert__warning {
  &:before {
    content: $icon-alert__warning;
  }
}
.icon-alert__warning--outline {
  &:before {
    content: $icon-alert__warning--outline;
  }
}
.icon-alert__help {
  &:before {
    content: $icon-alert__help;
  }
}
.icon-alert__help--outline {
  &:before {
    content: $icon-alert__help--outline;
  }
}
.icon-alert__info {
  &:before {
    content: $icon-alert__info;
  }
}
.icon-alert__info--outline {
  &:before {
    content: $icon-alert__info--outline;
  }
}
.icon-alert__pending {
  &:before {
    content: $icon-alert__pending;
  }
}
.icon-shopping-cart {
  &:before {
    content: $icon-shopping-cart;
  }
}
.icon-product__selected {
  &:before {
    content: $icon-product__selected;
  }
}
.icon-product__keep {
  &:before {
    content: $icon-product__keep;
  }
}
.icon-modal--close {
  &:before {
    content: $icon-modal--close;
  }
}
.icon-baggage-details {
  &:before {
    content: $icon-baggage-details;
  }
}
.icon-branded-fares__baggage-included {
  &:before {
    content: $icon-branded-fares__baggage-included;
  }
}
.icon-branded-fares__baggage-not-included {
  &:before {
    content: $icon-branded-fares__baggage-not-included;
  }
}
.icon-num-bags {
  &:before {
    content: $icon-num-bags;
  }
}
.icon-flight__direction {
  &:before {
    content: $icon-flight__direction;
  }
}
.icon-flight__duration {
  &:before {
    content: $icon-flight__duration;
  }
}
.icon-input__search {
  &:before {
    content: $icon-input__search;
  }
}
.icon-input__checkbox-toggle--selected {
  &:before {
    content: $icon-input__checkbox-toggle--selected;
  }
}
.icon-modify-search {
  &:before {
    content: $icon-modify-search;
  }
}
.icon-flight-stopover {
  &:before {
    content: $icon-flight__stopover;
  }
}
.icon-rating__full {
  &:before {
    content: $icon-rating__full;
  }
}
.icon-product__remove--outline {
  &:before {
    content: $icon-product__remove--outline;
  }
}
.icon-product__remove {
  &:before {
    content: $icon-product__remove;
  }
}
.icon-product__add--outline {
  &:before {
    content: $icon-product__add--outline;
  }
}
.icon-product__add {
  &:before {
    content: $icon-product__add;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-nav__dropdown {
  &:before {
    content: $icon-nav__dropdown;
  }
}
.icon-input__select-indicator {
  &:before {
    content: $icon-input__select-indicator;
  }
}
.icon-collapsible--closed {
  &:before {
    content: $icon-collapsible--closed;
  }
}
.icon-collapsible--opened {
  &:before {
    content: $icon-collapsible--opened;
  }
}
.icon-product__location--origin {
  &:before {
    content: $icon-product__location--origin;
  }
}
.icon-product__location--destination {
  &:before {
    content: $icon-product__location--destination;
  }
}
.icon-product__location--airport {
  &:before {
    content: $icon-product__location--airport;
  }
}
.icon-search-form__location--origin {
  &:before {
    content: $icon-search-form__location--origin;
  }
}
.icon-search-form__location--destination {
  &:before {
    content: $icon-search-form__location--destination;
  }
}
.icon-social__github {
  &:before {
    content: $icon-social__github;
  }
}
.icon-social__flickr {
  &:before {
    content: $icon-social__flickr;
  }
}
.icon-social__pintrest {
  &:before {
    content: $icon-social__pintrest;
  }
}
.icon-social__tumblr {
  &:before {
    content: $icon-social__tumblr;
  }
}
.icon-social__twitter {
  &:before {
    content: $icon-social__twitter;
  }
}
.icon-social__xing {
  &:before {
    content: $icon-social__xing;
  }
}
.icon-social__googleplus {
  &:before {
    content: $icon-social__googleplus;
  }
}
.icon-social__facebook {
  &:before {
    content: $icon-social__facebook;
  }
}
.icon-social__instagram {
  &:before {
    content: $icon-social__instagram;
  }
}
.icon-social__linkedin {
  &:before {
    content: $icon-social__linkedin;
  }
}
.icon-social__vimeo {
  &:before {
    content: $icon-social__vimeo;
  }
}
.icon-social__youtube {
  &:before {
    content: $icon-social__youtube;
  }
}
.icon-product__deal-loyalty {
  &:before {
    content: $icon-product__deal-loyalty;
  }
}
.icon-adults-only {
  &:before {
    content: $icon-adults-only;
  }
}
.icon-open__external-link {
  &:before {
    content: $icon-open__external-link;
  }
}
.icon-map-marker {
  &:before {
    content: $icon-map-marker;
  }
}
 .icon-product__lowest-price {
  &:before {
    content: $icon-product__lowest-price;
  }
}

.icon-payment-options {
  &:before {
    content: $icon-payment-options;
  }
}

.icon-marcus-pay {
  vertical-align: middle;
  //temp using image until we get an svg
  background: $icon-marcus-pay-light no-repeat 0 0;
  width: 77px;
  height: 17px;
}

.icon-view-edit {
  &:before {
    content: $icon-view-edit;
  }
}
.icon-flight__featured {
  &:before {
    content: $icon-flight__featured;
  }
}

.icon-branded-fares__available-for-charge {
  &:before {
    content: $icon-branded-fares__available-for-charge;
  }
}

.icon-branded-fares__included {
  &:before {
    content: $icon-branded-fares__included;
  }
}

.icon-branded-fares__not-available {
  &:before {
    content: $icon-branded-fares__not_available;
  }
}

.icon-product-notice__enhanced-health-measures-badge {
  &:before {
    content: $icon-product-notice__enhanced-health-measures-badge;
  }
}

.icon-image-gallery {
  &:before {
    content: $icon-image-gallery;
  }
}

.icon-product-amenity__no {
  &:before {
    content: $icon-product-amenity__no;
  }
}

.icon-product-amenity__yes {
  &:before {
    content: $icon-product-amenity__yes;
  }
}

.icon-product-amenity__fee {
  &:before {
    content: $icon-product-amenity__fee;
  }
}

.icon-trip__booked {
  &:before {
    content: $icon-trip__booked;
  }
}

.icon-trip__modified {
  &:before {
    content: $icon-trip__modified;
  }
}

.icon-trip__cancelled {
  &:before {
    content: $icon-trip__cancelled;
  }
}

.icon-trip__payment-due {
  &:before {
    content: $icon-trip__payment-due;
  }
}

.icon-trip__saved {
  &:before {
    content: $icon-trip__saved;
  }
}

.icon-product__bundles-no-air {
  &:before {
    content: $icon-product__bundles-no-air;
  }
}

.icon-chat-bubble {
  &:before {
    content: $icon-chat-bubble;
  }
}

.icon-price-drop{
  &:before {
    content: $icon-price_drop;
  }
}