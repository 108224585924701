//
// tags
// --------------------------------------------------

.tag {
  display: inline-block;
  padding: $tag-padding-base;
  position: relative;
  top: -1px;
  font-size: $tag-font-size;
  font-weight: $tag-font-weight !important;
  text-align: left;
  //white-space: nowrap; a11y needs tag to wrap so it doesnt run off the page
  vertical-align: baseline;
  border-radius: $tag-border-radius;

  &.tag-pill {
    border-radius: 10em;
  }

  &.tag-md {
    padding: $tag-padding-md;
    font-size: $tag-font-size-md;
    font-weight: $tag-font-weight-md;
  }

  &.tag-lg {
    padding: $tag-padding-lg;
    font-size: $tag-font-size-lg;
    border-radius: $tag-large-border-radius;
  }

  .icon, i {
    margin-right: 3px;
  }

  &.tag-price-drop {
    padding: $padding-small-horizontal/2 $padding-small-horizontal;
    border-radius: 16px;
    @include box-shadow($shadow-lg);
  }
}

.tag-inline > .tag + .tag {
  margin-left: $tag-inline-offset-width;
}

// Colors
// Contextual variations (linked tags get darker on :hover)

.tag-default {
  @include c-tag-variant($tag-default-bg, $tag-default-text-color);
}

.tag-primary {
  @include c-tag-variant($tag-primary-bg, $tag-primary-text-color);
}

.tag-success {
  @include c-tag-variant($tag-success-bg, $tag-success-text-color);
}

.tag-price-drop {
  background-color: $tag-price-drop-bg;
  color: $tag-price-drop-color;
}

.tag-success-alt {
  background-color: $tag-success-alt-bg;
  color: $tag-success-alt-text;
}

.tag-info {
  @include c-tag-variant($tag-info-bg, $tag-info-text-color);
}

.tag-warning {
  @include c-tag-variant($tag-warning-bg, $tag-warning-text-color);
}

.tag-danger {
  @include c-tag-variant($tag-danger-bg, $tag-danger-text-color);
}

.tag-special {
  background: $tag-special-bg-color;
  color: $tag-special-text-color;

  .icon, i {
    color: $tag-special-icon-color;
  }
}